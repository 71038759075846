import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";
import Layout from "../components/layout";

const Page = ({
  data: {
    wordpressPage: { title, content, slug },
  },
}) => {
  return (
    <Layout>
      <SEO title={title} pathname={slug} />
      <MiniHero name={title} />

      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4">
          <section className="col-span-4 p-20">
            <div
              className="entry-content"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </section>

        </div>
      </div>

    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
    }
  }
`;
